<template>
	<div class="dashboard">
    <div class="dashboard__container mb-5 pb-5">
      <div class="dashboard__container--header pa-0 flex-column align-start" style="align-items:flex-start;">
        <h1 class="mb-2">My Job Skills and Capabilities</h1>
        <p>Help us give you more work opportunities and place you in the jobs that are the best fit. Below, you can update your job skills, complete a quick questionairre, and apply to be a shift lead.</p>
      </div>
      <hr>
      <div class="dashboard__container--body pt-3">
        <div class="dashboard__container--body--col flex">
          <Card :status="hasSkills" :url="`account/skills`" :title="`Job Skills`" :description="`Choose the skills you have so we can match you with the appropriate jobs. For example: Usher, Bartender, Janitor, etc. We update the list frequently so be sure to check back.`" />
        </div>
        <div class="dashboard__container--body--col flex" v-if="userProfile.workerId">
          <Card :status="hasInterview" url="/account/interview" :title="`Job Placement Questionnaire`" :description="`Answer some basic questions about your availability and experience.`" />
        </div>
        <!-- <div class="dashboard__container--body--col flex" v-if="userProfile.workerId">
          <Card :status="hasLead" url="/lead" :title="`Apply to be a Shift Lead`" :description="`Are you comforable managing a small group of workers? Shift leads require extra training and have more responsibility. If you think you are up for it, please apply.`" />
        </div> -->
        <!-- <div class="dashboard__container--body--col flex" v-if="userProfile.employeeId && userProfile.employeeOnboardingComplete">
          <Card status="blank" url="`account/harassment`" :title="`My Workers' Compensation Insurance`" :description="`You must complete your Sexual Harassment Prevention Training to work in California. Please uplaod your certificate when complete. Click for details.`" />
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import Card from '@/components/Modules/Card.vue'

export default {
  name: 'skills',
  computed: {
    ...mapState(['currentUser', 'userProfile']),
    hasSkills() {
      if (this.userProfile && this.userProfile.skills && this.userProfile.skills.length >= 1) {
        return "approved"
      } else {
        return false
      }
    },
    hasInterview() {
      if (this.userProfile && this.userProfile.interview) {
        return "approved"
      } else {
        return false
      }
    },
    hasLead() {
      if (this.userProfile && this.userProfile.leadApplicationSubmitted) {
        return "approved"
      } else {
        return false
      }
    },
  },
  components: {
    Loader,
    Card
  },
  
  beforeDestroy () {
  }
}
</script>